import { Text } from '@nike/eds'
import React from 'react'

import SVG from '../assets/heroSVG.js'

export default function Hero() {
  return (
    <div className='hero eds--dark'>
      <div className='container hero-content'>
        <SVG />
        <div className='hero-message'>
          <Text font={'display-2'} as={'h1'}>
            Documentation
          </Text>
          <Text font={'body-2'} as={'p'}>
            Find guides, best practices, tutorials, API references and more to learn how to leverage
            select Global Technology solutions.
          </Text>
        </div>
      </div>
    </div>
  )
}
