import client from './client.js'
import { setGlobalHeader } from '../util/http.js'

export function bindClientToRedux(store) {
  const resetAuth = () => {
    setGlobalHeader('Authorization', null)
  }

  client.on('login:expired', () => {
    resetAuth()
  })

  client.on('login:success', ({ accessToken }) => {
    setGlobalHeader('Authorization', 'Bearer ' + accessToken.accessToken)
  })

  client.on('renew:access:success', (accessToken) => {
    setGlobalHeader('Authorization', 'Bearer ' + accessToken.accessToken)
  })

  client.on('renew:error', (error) => {
    console.warn(error)
    resetAuth()
  })

  client.on('login:error', (error) => {
    console.warn(error)
  })

  client.on('logout:success', () => {
    resetAuth()
  })
}
