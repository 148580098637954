import gql from 'graphql-tag'

export const TechSolutionBriefFrag = gql`
  fragment TechSolutionBriefFrag on TechSolution {
    __typename
    id
    name
    url
    owningTeam
    visible
    devPortalProjectName
  }
`

export const TechSolutionFilterableFrag = gql`
  fragment TechSolutionFilterableFrag on TechSolution {
    __typename
    ...TechSolutionBriefFrag
    description
    devPortalProjectName
  }
  ${TechSolutionBriefFrag}
`
