import React from 'react'
import { Link } from 'raviger'
import { Text } from '@nike/eds'

import { setRecentCache } from '../util/cache-operations.js'

function PlatformList({ platforms }) {
  return (
    platforms && (
      <div className='category-layout'>
        {platforms &&
          platforms.map((platform) => {
            return (
              <div key={platform.name} className='category-item'>
                <div>
                  <Text font='title-6'>{platform.name}</Text>
                  <div className='eds-type--body-3 category-products'>
                    {platform?.techSolutions.map((solution) => {
                      return (
                        <Link
                          key={solution.id}
                          onClick={() => setRecentCache(solution)}
                          href={`/${solution.devPortalProjectName}`}
                        >
                          {solution.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    )
  )
}

export default PlatformList
