import React from 'react'

export default function HeroSVG() {
  return (
    <svg height='101px' viewBox='0 0 385 511' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>ClipBoard</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Artboard' transform='translate(-994.000000, -175.000000)'>
          <g id='ClipBoard' transform='translate(998.000000, 175.000000)'>
            <g id='board'>
              <rect
                id='Rectangle'
                stroke='#FFFFFF'
                strokeWidth='7'
                x='0'
                y='36'
                width='377'
                height='471'
                rx='6'
              ></rect>
              <g id='clip' transform='translate(84.000000, 0.000000)' fill='#FFFFFF'>
                <path
                  d='M105,0 C119.109498,0 132.987563,6.05066821 146.634193,18.1520046 L147.227379,18.6819623 L207.6,18.6819623 C208.925483,18.6819623 210,19.7564789 210,21.0819623 L210,51.6 C210,52.9254834 208.925483,54 207.6,54 L2.4,54 C1.0745166,54 5.49139542e-15,52.9254834 0,51.6 L0,21.0819623 C-1.92822353e-15,19.7564789 1.0745166,18.6819623 2.4,18.6819623 L59.9820912,18.6819623 C60.5330749,18.6819554 61.0672802,18.4923728 61.4950011,18.1450432 C76.3915413,6.04834775 90.8932076,0 105,0 Z M105.39924,10.4270102 C101.871337,10.4270102 99.0114068,13.2909439 99.0114068,16.8237851 C99.0114068,20.3566264 101.871337,23.22056 105.39924,23.22056 C108.927142,23.22056 111.787072,20.3566264 111.787072,16.8237851 C111.787072,13.2909439 108.927142,10.4270102 105.39924,10.4270102 Z'
                  id='Combined-Shape'
                ></path>
              </g>
            </g>
            <g id='Group-2' transform='translate(39.000000, 81.000000)'>
              <circle id='Oval' fillOpacity='0.5' fill='#FFFFFF' cx='42' cy='42' r='42'></circle>
              <g id='Words' transform='translate(0.431392, 4.250000)'>
                <line
                  x1='106.568608'
                  y1='0.75'
                  x2='297.568608'
                  y2='0.75'
                  id='Path-9'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='162.25'
                  x2='266.715696'
                  y2='162.75'
                  id='Path-9-Copy-6'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='106.784304'
                  y1='54.25'
                  x2='297.463834'
                  y2='54.75'
                  id='Path-9-Copy-2'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='216.25'
                  x2='126.568608'
                  y2='216.75'
                  id='Path-9-Copy-8'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='108.25'
                  x2='287.5'
                  y2='108.75'
                  id='Path-9-Copy-5'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='270.25'
                  x2='106.568608'
                  y2='270.75'
                  id='Path-9-Copy-10'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <g
                  id='Chart'
                  transform='translate(157.000000, 178.750000)'
                  fill='#FFFFFF'
                  fillOpacity='0.5'
                >
                  <rect id='Rectangle' x='0' y='20.25' width='25' height='74'></rect>
                  <rect id='Rectangle-Copy' x='38.5686081' y='0' width='25' height='94'></rect>
                  <rect id='Rectangle-Copy-2' x='77.5686081' y='63' width='25' height='29'></rect>
                  <rect id='Rectangle-Copy-3' x='116.568608' y='28' width='25' height='64'></rect>
                </g>
                <line
                  x1='0'
                  y1='350.25'
                  x2='287.5'
                  y2='350.75'
                  id='Path-9-Copy-12'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='106.784304'
                  y1='28.25'
                  x2='243.784304'
                  y2='28.25'
                  id='Path-9-Copy'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='106.784304'
                  y1='82.25'
                  x2='243.784304'
                  y2='82.25'
                  id='Path-9-Copy-3'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='135.25'
                  x2='213'
                  y2='135.25'
                  id='Path-9-Copy-4'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='189.25'
                  x2='106.568608'
                  y2='189.75'
                  id='Path-9-Copy-7'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='243.25'
                  x2='95.5686081'
                  y2='243.75'
                  id='Path-9-Copy-9'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='297.25'
                  x2='214'
                  y2='297.25'
                  id='Path-9-Copy-11'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='324.25'
                  x2='213'
                  y2='324.25'
                  id='Path-9-Copy-13'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
                <line
                  x1='0'
                  y1='378.25'
                  x2='213'
                  y2='378.25'
                  id='Path-9-Copy-14'
                  strokeOpacity='0.5'
                  stroke='#FFFFFF'
                  strokeWidth='2'
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
