import { combineReducers } from 'redux'
import { parseErrorMiddleware } from '@nike/redux-action-utils'
import offline from '../offline/reducer.js'

const rootReducer = combineReducers({
  parseErrorMiddleware,
  offline,
})

export default rootReducer
