import React, { useState } from 'react'
import { Link, useQueryParams } from 'raviger'
import { Icon } from '@nike/eds'
import Collapsible from 'react-collapsible'

const Trigger = ({ open, title, isNested, type }) => (
  <div className='trigger-icon-container'>
    <Icon name={open ? 'CaretDown' : 'CaretRight'} />
    <span className={`trigger-title ${isNested && type ? 'nested-trigger' : ''} eds-type--title-5`}>
      {title}
    </span>
  </div>
)

function CollapseSection({ children, title, nested }) {
  const open = !!localStorage.getItem(`${title}-is-open`)
  const [isOpen, setIsOpen] = useState(nested ? true : open)
  const [{ type }] = useQueryParams()

  const handleClick = () => {
    if (isOpen) {
      localStorage.removeItem(`${title}-is-open`)
      setIsOpen(false)
    } else {
      localStorage.setItem(`${title}-is-open`, true)
      setIsOpen(true)
    }
  }

  const header = nested && type ? <Header title={title} type={type} /> : title

  return (
    <div className='collapse-section'>
      <Collapsible
        transitionTime={50}
        open={isOpen}
        handleTriggerClick={nested && type ? () => null : handleClick}
        triggerClassName='trigger'
        triggerOpenedClassName='trigger'
        triggerWhenOpen={<Trigger title={header} type={type} isNested={nested} open />}
        trigger={<Trigger title={header} type={type} isNested={nested} />}
      >
        {children}
      </Collapsible>
    </div>
  )
}

function Header({ title, type }) {
  return (
    <span className='nested-header'>
      <Link className='nested-header-link' href='/'>
        {title}
      </Link>
      <span className='nested-header-icon'>{'>'}</span>
      {type}
    </span>
  )
}

export default CollapseSection
