import gql from 'graphql-tag'
import { TechSolutionFilterableFrag } from './fragments.js'

export const HOME_QUERY = gql`
  query {
    platforms(filterDocs: true) {
      __typename
      name
      icon
      techSolutions {
        __typename
        ...TechSolutionFilterableFrag
      }
    }
  }
  ${TechSolutionFilterableFrag}
`

export const HOME_SEARCH_QUERY = gql`
  query ($teams: [String]!) {
    techSolutions(teams: $teams, filterDocs: true) {
      __typename
      id
      name
      devPortalProjectName
    }
  }
`

export const searchQuery = gql`
  query ($teams: [String]!, $includeUnavailable: Boolean) {
    techSolutions(teams: $teams, includeUnavailable: $includeUnavailable) {
      __typename
      ...TechSolutionFilterableFrag
    }
  }
  ${TechSolutionFilterableFrag}
`

export const getUser = gql`
  query user($techSolutionId: String!) {
    user(techSolutionId: $techSolutionId) {
      email
      favorites {
        type
        id
        slug
        name
      }
    }
  }
`
