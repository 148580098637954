import { useCallback, useEffect } from 'react'

export const useKeyDown = (callback, keys, targetRef) => {
  const onKeyDown = useCallback(
    (event) => {
      const hasExpectedTarget = targetRef.current?.contains(event.target)
      const wasAnyKeyPressed = keys.some((key) => event.key === key)
      if (wasAnyKeyPressed && hasExpectedTarget) {
        event.preventDefault()
        callback()
      }
    },
    [keys, callback, targetRef]
  )
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])
}
