import React, { useState, useRef } from 'react'
import { TextField, Icon } from '@nike/eds'
import { useQueryParams, navigate } from 'raviger'

import config from '../config.js'
import Query from '../graphql/Query.js'
import { HOME_QUERY } from '../graphql/queries.js'
import { tryParse } from '../util/cache-operations.js'
import { useKeyDown } from '../util/useKeyDown.js'
import {
  Hero,
  TechSolutionList,
  PlatformList,
  CollapseSection,
  Card,
  /* eslint-disable-next-line */
} from '../components/'

function HomePage() {
  return (
    <div className='content-wrapper'>
      <Hero />
      <Query loader query={HOME_QUERY} render={(data) => <HomeContent data={data} />} />
    </div>
  )
}
function generalSection() {
  return (
    <CollapseSection title='General' nested>
      <div className='layout eds-type--body-4 card-layout'>
        <Card
          name='Developer Community'
          devPortalProjectName='Auto-Tools-Collaborate-Developer-Community'
        />
        <Card name='Developer Handbook' devPortalProjectName='Auto-Tools-Collaborate-Handbook' />
        <Card name='Inner Source' devPortalProjectName='Auto-Tools-Collaborate-Inner-Source' />
        <Card name='Open Source' devPortalProjectName='Auto-Tools-Collaborate-Open-Source' />
        <Card name='Platform Console' devPortalProjectName='platform-console' />
        <Card name='Security Compliance' devPortalProjectName='Auto-Tools-Security-Compliance' />
      </div>
    </CollapseSection>
  )
}

function HomeContent({ data }) {
  const { platforms } = data
  const techSolutions = platforms.reduce((acc, platform) => {
    return [...acc, ...platform.techSolutions]
  }, [])
  const [searchValue, setSearchValue] = useState('')
  const [params] = useQueryParams()

  const inputRef = useRef(null)
  const handleSearch = (e) => setSearchValue(e.target.value)

  const recents = tryParse(localStorage.getItem(config.recentPlatformStorageItem))

  useKeyDown(
    () => {
      if (searchValue) {
        // Preserves other params (redirect)
        // We want the replace: true that navigate has
        // setQuery wont properly replace history
        const search = new URLSearchParams(
          Object.entries({
            ...params,
            pcNavSearchQuery: searchValue,
          }).filter(([, v]) => v !== null)
        ).toString()
        navigate(`?${search}`, { replace: true })
      }
    },
    ['Enter'],
    inputRef
  )
  return (
    <div className='container home-body'>
      <div className='contentSearch'>
        <TextField
          id='searchContent-docs'
          ref={inputRef}
          type='text'
          label='Search Technology, Documentation, APIs, Templates and more'
          hideLabel
          placeholder='Search Technology, Documentation, APIs, Templates and more'
          beforeSlot={<Icon name='Search' />}
          onChange={handleSearch}
          data-external-id='search-bar-documentation-landing-page'
        />
      </div>
      <div className='home-content'>
        {recents && !!Object.keys(recents).length && (
          <CollapseSection title='Recent'>
            <TechSolutionList cache={recents} techSolutions={techSolutions} />
          </CollapseSection>
        )}
        {generalSection()}
        {platforms.length ? (
          <CollapseSection nested title='Platforms'>
            <PlatformList platforms={platforms} />
          </CollapseSection>
        ) : null}
      </div>
    </div>
  )
}

export default HomePage
