import { NarkPlatform } from '@nike/nark-sdk'
import config from '../config.js'

class ConsoleNark extends NarkPlatform {
  logRoute(route) {
    super.log({
      source: 'ui',
      feature: route,
      operation: 'navigate',
    })
  }
  log(data) {
    super.log({
      source: 'ui',
      operation: data.operation,
      feature: data.feature,
      data: { name: data.techSolutionName, ...data.data },
    })
  }
}

function NarkNavigation(token) {
  return new ConsoleNark({
    token,
    ...config.nark,
  })
}

export default NarkNavigation
