import React from 'react'
import Title from 'react-document-title'
import Documentation from './Documentation.js'

export default function Guide() {
  return (
    <>
      <Documentation isGuide platform='Docs' />
      <Title title='Guide' />
    </>
  )
}
