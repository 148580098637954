const redirectMap = {
  '/start/latest/documentation.html': '/',
  '/gem/latest/index.html': '/nike-streaming',
  '/gem/latest/basics.html': '/nike-streaming/Basics%20Concepts',
  '/gem/latest/sources/index.html': '/nike-streaming/Sources',
  '/gem/latest/sources/http.html': '/nike-streaming/Sources/HTTP%20Source',
  '/gem/latest/sources/oracle.html': '/nike-streaming/Sources/Oracle%20Source',
  '/gem/latest/sources/postgres.html': '/nike-streaming/Sources/PostgreSQL%20Source',
  '/gem/latest/sources/ibmmq.html': '/nike-streaming/Sources/IBM%20MQ%20Source',
  '/gem/latest/sinks/index.html': '/nike-streaming/Sinks',
  '/gem/latest/sinks/http.html': '/nike-streaming/Sinks/HTTP%20Sink',
  '/gem/latest/sinks/s3.html': '/nike-streaming/Sinks/S3%20Sink',
  '/gem/latest/sinks/snowflake.html': '/nike-streaming/Sinks/Snowflake%20Sink',
  '/gem/latest/reference/whitelisting.html': '/nike-streaming/IP%20Whitelisting',
  '/map/latest/index.html': '/managed-airflow-platform',
  '/map/latest/clusters.html': '/managed-airflow-platform/Introduction/Clusters',
  '/map/latest/architecture.html': '/managed-airflow-platform/Introduction/Architecture',
  '/map/latest/shared-resources.html':
    '/managed-airflow-platform/Introduction/Architecture/Shared%20Resources',
  '/map/latest/dedicated-resources.html':
    '/managed-airflow-platform/Introduction/Architecture/Dedicated%20Resources',
  '/map/latest/guides/quickstart/intro.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart',
  '/map/latest/guides/quickstart/step1.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart/Creating%20a%20Cluster',
  '/map/latest/guides/quickstart/step2.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart/View%20Cluster%20Details',
  '/map/latest/guides/quickstart/step3.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart/Create%20a%20DAG',
  '/map/latest/guides/quickstart/step4.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart/Uploading%20a%20DAG',
  '/map/latest/guides/quickstart/step5.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart/Airflow%20UI',
  '/map/latest/guides/quickstart/step6.html':
    '/managed-airflow-platform/How-To%20Guides/Quickstart/Clean%20Up',
  '/map/latest/guides/updating/updating.html':
    '/managed-airflow-platform/How-To%20Guides/Managing%20Clusters/Updating%20Clusters',
  '/map/latest/guides/updating/upgrading.html':
    '/managed-airflow-platform/How-To%20Guides/Managing%20Clusters/Upgrading%20Clusters',
  '/map/latest/guides/IAM/intro.html': '/managed-airflow-platform/How-To%20Guides/IAM',
  '/map/latest/guides/IAM/step1.html':
    '/managed-airflow-platform/How-To%20Guides/IAM/Creating%20a%20OIDC%20Provider',
  '/map/latest/guides/IAM/step2.html':
    '/managed-airflow-platform/How-To%20Guides/IAM/Creating%20an%20IAM%20Role',
  '/map/latest/guides/IAM/IAM-Role-session.html':
    '/managed-airflow-platform/How-To%20Guides/IAM/Increasing%20session%20duration%20for%20IAM%20credentials',
  '/map/latest/guides/byoc/intro.html':
    '/managed-airflow-platform/How-To%20Guides/Custom%20Containers',
  '/map/latest/guides/byoc/create-container.html':
    '/managed-airflow-platform/How-To%20Guides/Custom%20Containers/Create%20a%20Container',
  '/map/latest/guides/byoc/publish-container.html':
    '/managed-airflow-platform/How-To%20Guides/Custom%20Containers/Publish%20your%20Container',
  '/map/latest/guides/byoc/use-container.html':
    '/managed-airflow-platform/How-To%20Guides/Custom%20Containers/Use%20your%20Container%20in%20MAP',
  '/map/latest/guides/byoc/ecr.html':
    '/managed-airflow-platform/How-To%20Guides/Custom%20Containers/ECR%20Configuration',
  '/map/latest/guides/SSH/intro.html': '/managed-airflow-platform/How-To%20Guides/SSH',
  '/map/latest/guides/SSH/prereq.html':
    '/managed-airflow-platform/How-To%20Guides/SSH/Generating%20an%20SSH%20key%20pair',
  '/map/latest/guides/SSH/config.html':
    '/managed-airflow-platform/How-To%20Guides/SSH/Configure%20the%20SSH%20Connection',
  '/map/latest/guides/SSH/test.html':
    '/managed-airflow-platform/How-To%20Guides/SSH/Test%20the%20SSH%20Connection',
  '/map/latest/access/authentication.html': '/managed-airflow-platform/Access%20Management',
  '/map/latest/access/permissions.html':
    '/managed-airflow-platform/Access%20Management/Permissions',
  '/map/latest/basics/best-practices.html': '/managed-airflow-platform/Airflow%20Basics',
  '/map/latest/basics/concepts.html': '/managed-airflow-platform/Airflow%20Basics/Concepts',
  '/map/latest/basics/dags.html': '/managed-airflow-platform/Airflow%20Basics/DAGs',
  '/map/latest/basics/operators.html': '/managed-airflow-platform/Airflow%20Basics/Operators',
  '/map/latest/basics/task-lifecycle.html':
    '/managed-airflow-platform/Airflow%20Basics/Task%20Lifecycle',
  '/map/latest/basics/connections.html': '/managed-airflow-platform/Airflow%20Basics/Connections',
  '/map/latest/basics/configs.html': '/managed-airflow-platform/Airflow%20Basics/Configs',
  '/map/latest/advanced/airflow-apis.html': '/managed-airflow-platform/Advanced%20Airflow',
  '/map/latest/advanced/bitshift-composition.html':
    '/managed-airflow-platform/Advanced%20Airflow/Bitshift%20Composition',
  '/map/latest/advanced/relationship-helper.html':
    '/managed-airflow-platform/Advanced%20Airflow/Relationship%20Helper',
  '/map/latest/advanced/hooks.html': '/managed-airflow-platform/Advanced%20Airflow/Hooks',
  '/map/latest/advanced/pools.html': '/managed-airflow-platform/Advanced%20Airflow/Pools',
  '/map/latest/advanced/xcoms.html': '/managed-airflow-platform/Advanced%20Airflow/XComs',
  '/map/latest/advanced/variables.html': '/managed-airflow-platform/Advanced%20Airflow/Variables',
  '/map/latest/advanced/branching.html': '/managed-airflow-platform/Advanced%20Airflow/Branching',
  '/map/latest/advanced/subdags.html': '/managed-airflow-platform/Advanced%20Airflow/SubDAGs',
  '/map/latest/advanced/slas.html': '/managed-airflow-platform/Advanced%20Airflow/Task-DAG%20SLAs',
  '/map/latest/advanced/trigger-rules.html':
    '/managed-airflow-platform/Advanced%20Airflow/Trigger%20Rules',
  '/map/latest/advanced/latest-run-only.html':
    '/managed-airflow-platform/Advanced%20Airflow/Latest%20Run%20Only',
  '/map/latest/advanced/notes.html':
    '/managed-airflow-platform/Advanced%20Airflow/Documentation%20and%20Notes',
  '/map/latest/versioning/versioning.html': '/managed-airflow-platform/Versioning',
  '/map/latest/versioning/version-list.html':
    '/managed-airflow-platform/Versioning/Supported%20Version%20List',
  '/map/latest/versioning/python.html': '/managed-airflow-platform/Versioning/Python%20Versions',
  '/map/latest/versioning/base-containers.html':
    '/managed-airflow-platform/Versioning/Base%20Containers',
  '/map/latest/networking/networking.html': '/managed-airflow-platform/Networking',
  '/map/latest/networking/privatelink.html':
    '/managed-airflow-platform/Networking/AWS%20Privatelink',
  '/map/latest/networking/openports.html': '/managed-airflow-platform/Networking/Open%20Ports',
  '/map/latest/networking/cidr.html': '/managed-airflow-platform/Networking/IP%20Space',
  '/map/latest/monitoring/monitoring.html': '/managed-airflow-platform/Monitoring',
  '/map/latest/monitoring/best-practices.html':
    '/managed-airflow-platform/Monitoring/Best%20Practices',
  '/map/latest/monitoring/metrics-dashboard.html':
    '/managed-airflow-platform/Monitoring/Metrics%20Dashboard',
  '/map/latest/monitoring/logs-dashboard.html':
    '/managed-airflow-platform/Monitoring/Logs%20Dashboard',
  '/map/latest/monitoring/nsp-cluster-metrics.html':
    '/managed-airflow-platform/Monitoring/NSP%20Sources',
  '/map/latest/monitoring/nsp-dag-logs.html':
    '/managed-airflow-platform/Monitoring/NSP%20Sources/DAG%20Logs',
  '/map/latest/monitoring/nsp-runtime-logs.html':
    '/managed-airflow-platform/Monitoring/NSP%20Sources/Runtime%20Logs',
  '/map/latest/limitations/limitations.html': '/managed-airflow-platform/Limitations',
  '/map/latest/limitations/blocked-configs.html':
    '/managed-airflow-platform/Limitations/Blocked%20Configurations',
  '/map/latest/support/stability.html': '/managed-airflow-platform/Support/Stability',
  '/map/latest/support/maintenance.html': '/managed-airflow-platform/Support/Maintenance%20Windows',
  '/map/latest/support/scope.html': '/managed-airflow-platform/Support/Scope%20of%20Support',
  '/map/latest/support/sla.html': '/managed-airflow-platform/Support/Service%20Level%20Agreement',
  '/map/latest/support/contact.html': '/managed-airflow-platform/Support/Get%20Support',
  '/map/latest/release_notes/index.html': '/managed-airflow-platform/Release%20Notes',
  '/map/latest/mapclients/map_clients.html': '/managed-airflow-platform/Map%20Clients',
  '/map/latest/mapclients/bump_limits.html': '/managed-airflow-platform/Airflow%20limits',
  '/map/latest/map_operators/migration_operator.html':
    '/managed-airflow-platform/MAP%20Operators/Migration%20Operator',
}

export default redirectMap
