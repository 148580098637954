import React from 'react'
import Title from 'react-document-title'
import { useRoutes, usePath, useLocationChange, Redirect } from 'raviger'
import { useAuthStore, selectAccessToken, useAuth } from '@nike/aegis-auth-react'

import ConsoleProvider from './ConsoleProvider.js'
import ConsoleNav from './ConsoleNav.js'
import routes from './routes.js'
import NotFoundPage from './NotFoundPage.js'
import OfflineBanner from '../offline/OfflineBanner.js'
import { LoginRequired } from '../auth/index.js'
import PageSpinner from '../components/PageSpinner.js'
import redirectMap from '../redirects.js'
import config from '../config.js'
import NarkNavigation from '../util/nark.js'
import client from '../auth/client.js'

function AuthedApp({ children, token }) {
  const nark = token ? NarkNavigation(token) : null
  useLocationChange(
    ({ path }) => {
      nark && nark.logRoute(path)
    },
    { onInitial: true }
  )

  return children
}

const App = () => {
  const { loginComplete } = useAuth(client)
  const token = useAuthStore(selectAccessToken)
  const routeResult = useRoutes(routes) || <NotFoundPage />
  const path = usePath()

  if (path in redirectMap) return <Redirect to={redirectMap[path]} />

  if (!loginComplete) {
    return <PageSpinner />
  }

  return (
    <ConsoleNav>
      <ConsoleProvider auth={token}>
        <Title title={config.appName} />
        <OfflineBanner />
        <LoginRequired>
          <AuthedApp token={token}>
            <div>{routeResult}</div>
          </AuthedApp>
        </LoginRequired>
      </ConsoleProvider>
    </ConsoleNav>
  )
}

export default App
