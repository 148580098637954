import React from 'react'
import { Link } from 'raviger'
import { Link as EDSLink } from '@nike/eds'

import { setRecentCache } from '../util/cache-operations.js'

export default React.forwardRef((props, ref) => {
  const isPlatform = props.name && props.name !== props.devPortalProjectName
  const displayName = isPlatform ? props.name : props.devPortalProjectName

  return (
    <EDSLink
      as={Link}
      onClick={() => setRecentCache(props)}
      href={`/${props.devPortalProjectName}`}
    >
      <div
        ref={ref}
        data-href={`/${props.devPortalProjectName}`}
        className={`product-card ${props.className || ''}`}
      >
        <span className='eds-type--body-2'>{displayName.replace(/-/g, ' ')}</span>
      </div>
    </EDSLink>
  )
})
