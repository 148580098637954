import React from 'react'

import HomePage from '../pages/HomePage.js'
import Documentation from '../pages/Documentation.js'
import GuidePage from '../pages/GuidePage.js'

const routes = {
  '/': () => <HomePage />,
  '/guide': () => <GuidePage />,
  '/guide/*': () => <GuidePage />,
  '/:platform': ({ platform }) => <Documentation platform={platform.replace(/%20/g, ' ')} />,
  '/:platform/*': ({ platform }) => <Documentation platform={platform.replace(/%20/g, ' ')} />,
}

export default routes
