import React, { lazy, Suspense, useEffect, useRef } from 'react'
import { Link, navigate } from 'raviger'
import Title from 'react-document-title'
import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react'
import { useMutation, useQuery } from '@apollo/client'

import { updateUserRecents, sendEmail } from '../graphql/mutations.js'
import { FavoriteButton, PageSpinner } from '../components/index.js'
import { getUser } from '../graphql/queries.js'
import config, { isProd } from '../config.js'
import NarkNavigation from '../util/nark.js'

const Doctree = lazy(() =>
  import('@cx/docs-client').then((module) => ({ default: module.Documentation }))
)

export default function Documentation({ platform, isGuide }) {
  const token = useAuthStore(selectAccessToken)
  const nark = token ? NarkNavigation(token) : undefined
  const { data } = useQuery(getUser, {
    variables: {
      techSolutionId: config.platformConsoleTechSolutionId,
    },
  })

  const [sendEmailMutation] = useMutation(sendEmail)
  const [updateUserRecentsMutation] = useMutation(updateUserRecents)
  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      updateUserRecentsMutation({
        variables: {
          input: {
            type: 'DOC',
            id: platform,
          },
        },
      }).catch((e) => {
        console.warn('Failed to update recents')
      })
      return () => {
        mounted.current = false
      }
    }
    mounted.current = true
  }, [updateUserRecentsMutation, platform])

  function renderFavoriteButton() {
    return <FavoriteButton favorites={data?.user?.favorites || []} docName={platform} />
  }

  const onReportLink = async (linkData) => {
    if (linkData) {
      let targetEmail = data.user.email
      if (process.env.NODE_ENV === 'production' && isProd()) {
        targetEmail = linkData.adminEmail
      }

      nark.log({
        operation: 'reportBrokenLink',
        feature: 'reportableLink',
        techSolutionName: platform,
        data: {
          page: linkData.currentPage,
          href: linkData.href,
          text: linkData.text,
          to: targetEmail,
        },
      })
      await sendEmailMutation({
        variables: {
          subject: 'Broken link report in docs',
          to: targetEmail,
          body: `A user has reported a broken link on a documentation page that you own. Please review and update the page with current information. <br />
          The broken link is titled, "${linkData.text}" with url "${linkData.href}" and is located on this docs page: <a href='${linkData.currentPage}'>${linkData.currentPage}</a>`,
        },
      })
    }
  }

  return (
    <>
      <Title title={createDocumentTitle(platform)} />
      <Suspense fallback={<PageSpinner />}>
        <Doctree
          Link={Link}
          onNavigate={navigate}
          token={token}
          projectName={platform}
          homeBreadCrumbPath='/'
          homeBreadCrumbText='Home'
          favoriteButton={renderFavoriteButton()}
          projectAlias={isGuide ? 'guide' : null}
          basePath={`/${isGuide ? 'guide' : encodeURIComponent(platform)}`}
          onReportLink={onReportLink}
        />
      </Suspense>
    </>
  )
}

function createDocumentTitle(platform) {
  try {
    const pathEntries = window.location.pathname?.split('/')
    let pageName = decodeURIComponent(pathEntries[pathEntries.length - 1])
    pageName =
      pageName === platform ? pageName : pageName.charAt(0).toUpperCase() + pageName.slice(1)
    return pageName === platform ? pageName : `${pageName} - ${platform}`
  } catch (error) {
    return platform
  }
}
