import config from '../config.js'

export function setRecentCache(product) {
  const existing = parseStorageItem(config.recentPlatformStorageItem)
  const cacheKey = unifyPlatformTitle(product.devPortalProjectName)

  existing[cacheKey] = {}
  existing[cacheKey].name = product.name
  existing[cacheKey].devPortalProjectName = product.devPortalProjectName
    ? product.devPortalProjectName
    : product.name

  const newRecents = JSON.stringify(existing)

  localStorage.setItem(config.recentPlatformStorageItem, newRecents)
}

export function parseStorageItem(type, def = {}) {
  const existing = localStorage.getItem(type)
  return existing ? tryParse(existing) : def
}

export function unifyPlatformTitle(platformName) {
  return platformName.replace(/ /g, '-')
}

export function tryParse(obj) {
  try {
    const parsed = JSON.parse(obj)
    return parsed
  } catch (error) {
    return obj
  }
}
