import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import Root from './index.js'

const SingleSPAError = (err, info) => {
  console.warn(err)
  console.warn(info)
  return (
    <div
      style={{
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
        marginTop: '20%',
        marginLeft: '10%',
        marginRight: '10%',
      }}
    >
      <h1 className='eds-type--display-4'>Oops!</h1>
      <p className='eds-type--body-2 eds-spacing--m-16'>
        It looks like something went wrong. Sorry for the inconvenience.
      </p>
      <p className='eds-type--body-2 eds-spacing--m-16'>
        Please{' '}
        <a
          className='eds-link'
          href={window.location.href}
          onClick={(e) => {
            e.preventDefault()
            window.location.reload()
          }}
        >
          reload the page
        </a>
        . Reach out to{' '}
        <a
          rel='noopener noreferrer'
          target='blank'
          className='eds-link'
          href='https://nikedigital.slack.com/archives/CFXBSE2G6'
        >
          #devportal
        </a>{' '}
        if this issue persists.
      </p>
      <svg width='81' height='29' viewBox='0 0 162 58' fill='rgb(237,111,45)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M43.4266 51.6336C30.6535 57.1423 10.6081 63.4704 2.25731 49.7773C-2.40062 42.1763 0.553634 30.3441 7.42726 19.5489C11.8043 12.6469 17.4183 6.32344 23.0413 0C19.9239 5.16152 11.1155 22.4514 22.6426 31.0941C27.1283 34.4039 35.026 34.7511 44.8448 32.1402L162 0.518495L43.4266 51.6336Z'
          fill='rgb(237,111,45)'
        ></path>
      </svg>
    </div>
  )
}

const lifecycles = singleSpaReact({
  renderType: 'createRoot',
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary: SingleSPAError,
})

export const { bootstrap, mount, unmount } = lifecycles
