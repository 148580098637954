import React from 'react'

import './stylus/app.styl'
import AppRoot from './root/Root.js'

import * as serviceWorker from './serviceWorker.js'
import { watchOnlineState } from './offline/actions.js'
import configureStore from './root/store.js'
import { bindClientToRedux } from './auth/events.js'

export const store = configureStore()

bindClientToRedux(store)
watchOnlineState(store)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

function Root() {
  return <AppRoot store={store} />
}

export default Root
