import React from 'react'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'

import { setContext } from '@apollo/client/link/context'
import config from '../config.js'

const httpLink = createHttpLink({
  uri: config.platformConsoleApi,
})

const setAuthLink = (authorization) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization,
      },
    }
  })

const cache = new InMemoryCache()

;(async () => {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  })
})()

export default function ConsoleProvider({ children, auth }) {
  const authLink = setAuthLink(auth)
  const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    query: {
      fetchPolicy: 'cache-and-network',
    },
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
