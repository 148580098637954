import React from 'react'
import Card from './Card.js'

export default function TechSolutionList({ techSolutions, starred, cache, hasSummary }) {
  const className = `layout eds-type--body-2 card-layout`
  if (!cache) {
    return (
      <div className={className}>
        {techSolutions.map((product) => (
          <Card hasSummary={hasSummary} key={product.name} {...product} />
        ))}
      </div>
    )
  }

  const cachePlatforms = Object.values(cache).map((project) => {
    const cacheProducts = techSolutions.filter((product) => {
      return product.devPortalProjectName === project.devPortalProjectName
    })
    if (cacheProducts.length) {
      return cacheProducts[0]
    }
    return project
  })

  return (
    <div className={className}>
      {cachePlatforms.map((product, i) => (
        <Card starred={starred} hasSummary={hasSummary} key={`${product}-${i}`} {...product} />
      ))}
    </div>
  )
}
