import React from 'react'
import {
  selectAccessToken,
  selectIsLoggedIn,
  selectLoginComplete,
  useAuthStore,
} from '@nike/aegis-auth-react'

import IntegratedApp from '@nike/console-integrated-platform'
import { LoginRequired } from '../auth/index.js'

import config from '../config.js'

export default function ConsoleNav({ children }) {
  const isLoggedIn = useAuthStore(selectIsLoggedIn)
  const authCheckDone = useAuthStore(selectLoginComplete)
  const accessToken = useAuthStore(selectAccessToken) || ''

  return accessToken ? (
    <IntegratedApp
      name='platformconsole'
      isLoggedIn={isLoggedIn}
      hasAuthCheckFinished={authCheckDone}
      slackURL={config.consoleSlackChannel}
      accessToken={accessToken}
      showTopNav={false}
      showConsoleNav={true}
    >
      {children}
    </IntegratedApp>
  ) : (
    <LoginRequired />
  )
}
