import React, { useState } from 'react'
import { Icon, Tooltip, Snackbar, Snack, Spinner, IconButton } from '@nike/eds'
import { useMutation } from '@apollo/client'
import { updateUserFavorites } from '../graphql/mutations.js'
import { getUser } from '../graphql/queries.js'
import config from '../config.js'

const ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
}
export default function FavoriteButton({ docName, favorites }) {
  const [isSaving, setIsSaving] = useState(false)
  const [snack, setSnack] = useState(null)
  const [isFavorite, setIsFavorite] = useState(
    !!favorites.find((favorite) => favorite?.id === docName)
  )
  const [updateUserFavoritesMutation] = useMutation(updateUserFavorites)

  const label = isFavorite ? 'Remove Favorite' : 'Add Favorite'
  const action = isFavorite ? ACTIONS.REMOVE : ACTIONS.ADD

  const updateFavorite = async () => {
    try {
      setIsSaving(true)

      const { data } = await updateUserFavoritesMutation({
        variables: {
          action,
          input: {
            type: 'DOC',
            id: docName,
          },
        },
        refetchQueries: [
          {
            query: getUser,
            variables: { techSolutionId: config.platformConsoleTechSolutionId },
          },
        ],
      })

      const result = data?.updateUserFavorites

      if (!result) {
        setSnack({
          message: `Error updating ${docName}`,
          status: 'error',
        })
      }

      if (result) {
        const { message, success } = result
        if (success) {
          setIsFavorite(action === ACTIONS.ADD)
          setSnack({
            message: `${docName} ${message}`,
            status: action === ACTIONS.ADD ? 'success' : 'info',
          })
        }
        if (!success) {
          const errorMessage = message.includes('Favorites reached the limit')
            ? message
            : `${docName} ${message}`
          setSnack({
            message: errorMessage,
            status: 'error',
          })
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const onDismissSnack = () => {
    setSnack(null)
  }

  return (
    <>
      <Tooltip
        isDark
        label={label}
        role='tooltip'
        aria-hidden='true'
        bodySlot={label}
        hidden={isSaving}
      >
        <IconButton
          disabled={isSaving}
          onClick={updateFavorite}
          variant='secondary'
          size='small'
          icon={
            <>
              {!isSaving && (
                <Icon
                  name={isFavorite ? 'FavoriteFilled' : 'Favorite'}
                  color={isFavorite ? 'var(--eds-color-red-40)' : null}
                />
              )}
              {isSaving && <Spinner />}
            </>
          }
        />
      </Tooltip>

      {snack && (
        <Snackbar>
          <Snack status={snack.status} autoDismissDuration={6000} onDismiss={onDismissSnack}>
            {snack.message}
          </Snack>
        </Snackbar>
      )}
    </>
  )
}
