import merge from 'deepmerge'

// platform name is gap-docs-site
const narkPreprod = {
  platformId: '==bjBjhWpa9HxNExASJu2Dl',
  url: 'https://api-preprod.nark.nikecloud.com/v1',
}
const narkProd = {
  platformId: '==K_YcMRW1x_0wC-unVh--w',
  url: 'https://api.nark.nikecloud.com/v1',
}

// Console API
const platformConsoleApi = (env) =>
  env
    ? `https://api-${env}-console.platforms.nike.com/graphql`
    : 'https://api-console.platforms.nike.com/graphql'

const base = {
  appName: 'Documentation',
  appFullName: 'platform-console-docs-site',
  oAuth: {
    clientId: 'nike.niketech.platformconsole-docs-site',
    responseType: 'authorization_code',
    scopes: ['profile', 'openid', 'email', 'offline_access'],
  },
  recentPlatformStorageItem: 'PlatformConsoleDocsRecents',
  consoleSlackChannel: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
  platformConsoleUrl: 'https://console.platforms.nike.com',
  platformConsoleTechSolutionId: '20d80335-a38e-42ac-b9ae-593978881a4c',
  searchWhitelist: ['berlioux', 'platform-console', 'nmk-manifest'],
  nark: narkPreprod,
}

// site is https://dls1y4fz04sov.cloudfront.net/
const dev = merge(base, {
  oAuth: {
    redirectUri: window.location.origin,
    authorization: 'https://api.aegis.nikecloud.com/v1/qa/authorize',
    token: 'https://api.aegis.nikecloud.com/v1/qa/token',
  },
  docsApi: 'https://api-developer.preprod.niketech.com/v1',
  platformConsoleApi: platformConsoleApi('dev'),
})

const nonprod = merge(base, {
  oAuth: {
    redirectUri: window.location.origin,
    authorization: 'https://api.aegis.nikecloud.com/v1/qa/authorize',
    token: 'https://api.aegis.nikecloud.com/v1/qa/token',
  },
  docsApi: 'https://api-developer.preprod.niketech.com/v1',
  platformConsoleApi: platformConsoleApi('nonprod'),
})

const prod = merge(base, {
  isProduction: true,
  oAuth: {
    redirectUri: window.location.origin,
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize',
    token: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token',
  },
  docsApi: 'https://api-developer.niketech.com/prod/v1',
  platformConsoleApi: platformConsoleApi(),
  nark: narkProd,
})

export const isProd = () => window.location.origin.includes('//docs.platforms.nike.com')
const isNonprod = () => window.location.origin.includes('//docs-nonprod.platforms.nike.com')

// eslint-disable-next-line import/no-mutable-exports
let config
if (isProd()) {
  config = prod
} else if (isNonprod()) {
  config = nonprod
} else {
  config = dev
}

export default config
