import React from 'react'
import { Button } from '@nike/eds'
import { useAuthStore, selectIsLoggedIn, selectLoginComplete } from '@nike/aegis-auth-react'

import PageSpinner from '../components/PageSpinner.js'

const LoginRequired = ({ title, loginUser, children }) => {
  const loginComplete = useAuthStore(selectLoginComplete)
  const isLoggedIn = useAuthStore(selectIsLoggedIn)

  if (!loginComplete) {
    return <PageSpinner title={title} noHeader />
  }

  if (!isLoggedIn) {
    return (
      <div style={{ margin: '0' }}>
        <p>You must be logged in to view this page</p>
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  return React.Children.only(children)
}

// eslint-disable-next-line react/display-name
const requireLogin = (title) => (WrappedComponent) => (props) =>
  (
    <LoginRequired title={title}>
      <WrappedComponent {...props} />
    </LoginRequired>
  )

export { LoginRequired }
export default requireLogin
