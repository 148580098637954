import gql from 'graphql-tag'

export const updateUserRecents = gql`
  mutation updateUserRecents($input: RecentInput!) {
    updateUserRecents(input: $input) {
      id
    }
  }
`

export const updateUserFavorites = gql`
  mutation updateUserFavorites($action: ActionType!, $input: FavoriteInput!) {
    updateUserFavorites(action: $action, input: $input) {
      message
      success
    }
  }
`

export const sendEmail = gql`
  mutation sendEmail($subject: String!, $to: String!, $body: String!) {
    sendEmail(subject: $subject, to: $to, body: $body)
  }
`
